import React from 'react'
import { Link } from "gatsby"
import { useScrollPosition } from "../../hooks/useScrollPosition"
import Menu from "./menu"
import Logo from "../../../static/images/logo/logo.svg"
import MailIcon from "../../../static/images/svgImportedAsComponent/mail.svg"

const NormalHeader = ({ page }) => {
    const scrollPosition = useScrollPosition()

    return (
        <header>
            <div className="header">
                <div className="show-white">
                    <div className="sticky">
                        <div>
                            <Link to="/" title="monotein"><img src={Logo} alt="Logo"/></Link>
                        </div>
                        <Menu />
                        <div className={`contact-button ${page === "Index" && 400 > scrollPosition && "contact-button-off"}`}>
                            <Link to="/contact">
                                <MailIcon/>
                                <span>コンタクト</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default NormalHeader
